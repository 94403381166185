import React from "react"
import { createPortal } from "react-dom"
import "../assets/styles/components/sidebar.scss"
import { Link } from "gatsby"

type props = {
  isDarkMode: boolean
  isSidebar: boolean
}

const isBrowser = typeof window !== "undefined"

const Sidebar: React.FC<props> = ({ isDarkMode, isSidebar }) => {
  if (!isBrowser) return null

  if (isSidebar) document.body.style.overflowY = "hidden"
  else document.body.style.overflowY = "visible"

  return createPortal(
    <>
      <nav
        className={`${isSidebar ? "side-navigation active" : "side-navigation"} ${
          isDarkMode === true ? "dark" : "light"
        }`}
      >
        <button className="cta-button css-1o0t58h" type="button" aria-label="">
          <a
            href="https://api.whatsapp.com/send/?phone=2347047066923&text&type=phone_number&app_absent=0"
            className="list-item"
            target="_blank"
            rel="noreferrer"
          >
            <span className="css-1lrl7yk">Contact Us</span>
            <span className="css-16yaint">Contact Us</span>
          </a>
        </button>
        <button className="cta-button css-1o0t58h" type="button" aria-label="">
          {/* <Link to="/contact" className="list-item">
            <span className="css-1lrl7yk">Switch to hosting</span>
            <span className="css-16yaint">Switch to hosting</span>
          </Link> */}
        </button>
        <button className="cta-button css-1o0t58h non-border" type="button" aria-label="">
          <Link to="/faq" className="list-item">
            <span className="css-1lrl7yk">FAQs</span>
            <span className="css-16yaint">FAQs</span>
          </Link>
        </button>
        <button className="cta-button css-1o0t58h non-border" type="button" aria-label="">
          <Link to="/doc/" className="list-item">
            <span className="css-1lrl7yk">Blog</span>
            <span className="css-16yaint">Blog</span>
          </Link>
        </button>
        <button className="cta-button css-1o0t58h non-border" type="button" aria-label="">
          <a
            href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3RJhjDrPHRXajJoG_vhVH4ZJivBb4gOLFF3fk1wb1tSnVrA8q7nNgRqGxfeqBwPmIbmmuIRGTF"
            className="list-item"
          >
            <span className="css-1lrl7yk">Book Call</span>
            <span className="css-16yaint">Book Call</span>
          </a>
        </button>
        <button className="cta-button css-1o0t58h non-border" type="button" aria-label="">
          <Link to="/terms" className="list-item">
            <span className="css-1lrl7yk">Terms of Use</span>
            <span className="css-16yaint">Terms of Use</span>
          </Link>
        </button>
      </nav>
    </>,
    document.body
  )
}

export default Sidebar
