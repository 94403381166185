import React from "react"
import "../assets/styles/page/faq.scss"
import Header from "../components/header"
import Accordion from "../components/accordion"

const Faq = () => {
  const accordionData = [
    {
      title: "Is the right agent an important factor in renting a short-let house?",
      content: `The role of an agent is to find suitable tenants for an apartment on behalf of the landlord. It's critical to get it right.`
    },
    {
      title: "Is short letting a part of real estate one can invest in?",
      content: `Short-letting is a simple aspect of the real estate industry that is currently thriving in Africa. It grows in value over time and is becoming more popular than hotels. Short-letting is mainstream because of its flexibility and short-term tenancy.`
    },
    {
      title: "What type of apartments can be short-letted?",
      content: `From a self-contained studio apartment to a bungalow or duplex, there is something for everyone. Depending on the individual's needs and affordability.`
    }
  ]
  return (
    <div className="faq--wrapper">
      <div className="header--wrapper">
        <Header isDarkMode={false} />
      </div>
      <div className="faq--content--wrapper">
        <div className="faq--header--text">
          <h2>FAQs</h2>
          <p>Answers to the most frequently asked questions.</p>
        </div>
        <div className="faq--content">
          <Accordion title={"What is short-letting?"}>
            <div>
              A short-let is a stay of at least one week and less than six months that is fully furnished and includes
              all necessary amenities such as electricity, water, gas, and so on. You have as much privacy as you want.
            </div>
          </Accordion>

          <Accordion title={"How long is a short-term rental?"}>
            <div>
              Short letting an apartment can last for any length of time, up to about six months, after which the
              tenancy would be classified as a "long term" rental.
            </div>
          </Accordion>

          <Accordion title={"How do the short letting services with Brooi work?"}>
            <div>
              We connect you with short-term rental homeowners, agents, and landlords who can provide you with the most
              comfortable and best-suited apartments for your vacation, business trip, or even house party.
            </div>
          </Accordion>

          <Accordion title={"Who are our ideal customers?"}>
            <div>
              Brooi aims to reach professionals on vacation, corporate individuals looking for a home-like environment
              in which to relax, and young people looking for a place to hang out. We hope to work with anyone who needs
              short-letting services.
            </div>
          </Accordion>
          <Accordion title={"What can I expect from a stay in a short-let apartment?"}>
            <div>
              Ideal for travelers seeking a luxury home away from home with the flexibility and privacy required for
              vacation or business travel. You get all-around power, a proper network connection, and so on.
            </div>
          </Accordion>
          <Accordion title={"What should I look out for before renting a short-let apartment?"}>
            <div>
              <ul accordion--ul>
                <li>Location & budget</li>
                <li>Duration of stay</li>
                <li>Close proximity to the best vacation spots and meeting locations</li>
                <li>Security</li>
                <li>Quality of services</li>
              </ul>
            </div>
          </Accordion>

          <Accordion title={"What are the steps required in renting a short-let apartment?"}>
            <div>
              <ul className="accordion--ul">
                <li>Choose the best location.</li>
                <li>Look through the listings.</li>
                <li>Contact the agent to go over the apartment's specifications.</li>
                <li>Read and sign the policy and agreement.</li>
              </ul>
            </div>
          </Accordion>

          <Accordion title={"Is the right agent an important factor in renting a short-let house?"}>
            <div>
              The role of an agent is to find suitable tenants for an apartment on behalf of the landlord. It's critical
              to get it right.
            </div>
          </Accordion>

          <Accordion title={"Is short letting a part of real estate one can invest in?"}>
            <div>
              Short-letting is a simple aspect of the real estate industry that is currently thriving in Africa. It
              grows in value over time and is becoming more popular than hotels. Short-letting is mainstream because of
              its flexibility and short-term tenancy.
            </div>
          </Accordion>

          <Accordion title={"What type of apartments can be short-letted?"}>
            <div>
              From a self-contained studio apartment to a bungalow or duplex, there is something for everyone. Depending
              on the individual's needs and affordability.
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default Faq
