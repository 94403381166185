import React, { useState, useEffect, FunctionComponent } from "react"
import "../assets/styles/components/header.scss"
import Sidebar from "./sidebar"
import BrooiLogo from "../assets/images/brooi_icon_blue.png"
import * as FaIcons from "react-icons/fa"
import * as AiIcons from "react-icons/ai"
import { Link } from "gatsby"

interface HeaderProps {
  isDarkMode: boolean
}

const IndexPage: FunctionComponent<HeaderProps> = ({ isDarkMode }) => {
  const [isSidebar, setIsSidebar] = useState(false)

  const showSidebar = () => setIsSidebar(!isSidebar)

  return (
    <>
      <nav className={`nav-wrapper ${isDarkMode === true ? "dark" : "light"}`}>
        <header className="navigation-wrapper">
          <Link to="/" className="logo--wrapper">
            <div className="svg--wrapper">
              <img src={BrooiLogo} alt="Logo" width="30" height="32" className="logo" />
            </div>
          </Link>

          <nav className="navigation-section">
            <button className="cta-button css-1o0t58h" type="button" aria-label="">
              <a
                href="https://api.whatsapp.com/send/?phone=2347047066923&text&type=phone_number&app_absent=0"
                className="list-item"
                target="_blank"
                rel="noreferrer"
              >
                <span className="css-1lrl7yk">Contact Us</span>
                <span className="css-16yaint">Contact Us</span>
              </a>
            </button>
            {/* <button className="cta-button css-1o0t58h" type="button" aria-label="">
              <Link to="/contact" className="list-item">
                <span className="css-1lrl7yk">Switch to hosting</span>
                <span className="css-16yaint">Switch to hosting</span>
              </Link>
            </button> */}
            <button className="cta-button css-1o0t58h non-border" type="button" aria-label="">
              <Link to="/faq" className="list-item">
                <span className="css-1lrl7yk">FAQs</span>
                <span className="css-16yaint">FAQs</span>
              </Link>
            </button>
            <button className="cta-button css-1o0t58h non-border" type="button" aria-label="">
              <Link to="/doc/" className="list-item">
                <span className="css-1lrl7yk">Blog</span>
                <span className="css-16yaint">Blog</span>
              </Link>
            </button>
            <button className="cta-button css-1o0t58h non-border" type="button" aria-label="">
                <a
                  href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3RJhjDrPHRXajJoG_vhVH4ZJivBb4gOLFF3fk1wb1tSnVrA8q7nNgRqGxfeqBwPmIbmmuIRGTF"
                  className="list-item"
                >
                  <span className="css-1lrl7yk">Book Call</span>
                  <span className="css-16yaint">Book Call</span>
                </a>
              </button>
          </nav>

          <Link to="#" className={`${isSidebar ? "menu-bars" : "menu-bars active-button"}`}>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          <Link to="#" className={`${isSidebar ? "exit-bar active-button" : "exit-bar"}`}>
            <AiIcons.AiOutlineClose className="exit" onClick={showSidebar} />
          </Link>
          <Sidebar isDarkMode={isDarkMode} isSidebar={isSidebar} />
        </header>
      </nav>
    </>
  )
}

export default IndexPage
