import React, { useState, useRef } from "react"
import Chevron from "../components/icons/chevron"
import "../assets/styles/components/accordion.scss"

function Accordion(props: any) {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("accordion__icon")

  const content = useRef<HTMLDivElement>(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(setActive === "active" ? "0px" : `${content.current?.scrollHeight ?? 0 + 10}px`)
    setRotateState(setActive === "active" ? "accordion__icon" : "accordion__icon rotate")
  }

  return (
    <div className="accordion__section">
      <section className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <div className="flex--wrapper">
          <div className="title--wrapper">
            <p className="accordion__title">{props.title}</p>
          </div>
          <div className="icon--wrap">
            <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
          </div>
        </div>
        <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion__content">
          <div className="accordion__text">{props.children}</div>
        </div>
      </section>
    </div>
  )
}

export default Accordion
